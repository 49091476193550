
















































import { Component, Vue } from "vue-property-decorator";
import { namespace } from 'vuex-class';
const ApplicationModule = namespace('ApplicationModule');
import Icon from '../components/Icon.vue';

@Component({
  components: {
    Icon,
  }
})
export default class Profile extends Vue {
  profile = {};

  @ApplicationModule.Action
  public getProfile!: () => Promise<[]>;

  async created() {
    const response = await this.getProfile();
    this.profile = response;
  }
}
